<template>
	<aside class="main-sidebar clearfix">
		<div class="right-Warp">
			<div style='border-top: 1px solid #eee;position: relative;' :class="['right_btn',iconIndex==1?'check':'']"
				@mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseEnter(0)">
				<img v-if="iconIndex==1" src="@/assets/icon/kefu1.png" class='ricon' alt="">
				<img v-else src="@/assets/icon/kefu.png" class='ricon' alt="">
				<div :class="['rightText',iconIndex==1?'checkText':'']">
					专属客服
				</div>
				<div v-if="showTan" @mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseEnter(0)"
					style='position: absolute;width: 120px;background-color: #ed2920;left: -130px;border-radius: 10px; top: 0px;'>
					<div style="text-align: center;color: #fff;margin-top: 20px;">
						企业微信二维码
					</div>
					<div
						style="margin-top: 10px;background-color: #fff;box-sizing: border-box;padding: 5px;width: 100px;margin-left: 10px;border-radius: 5px;">
						<img :src="er_code" style="width: 90px;height: 90px;border-radius: 5px;" alt="">
					</div>
					<div
						style="margin-top: 10px;background-color: #fff;box-sizing: border-box;padding: 5px;width: 100px;margin-left: 10px;border-radius: 5px;">
						<div style="text-align: center;color: #ed2920;">咨询热线</div>
						<div style="text-align: center;color: #ed2920;font-size: 12px;">{{one_tel}}</div>
						<div style="text-align: center;color: #ed2920;font-size: 12px;">{{two_tel}}</div>
					</div>
					<div @click='goKefus'>
						<img src="./kefu3.png" style="width: 30px;height: 30px;margin-top: 25px;" alt="">
						<div style="color: #fff;margin-bottom: 20px;margin-top: 10px;">
							在线客服
						</div>
					</div>
				</div>
				<div style='position: absolute;width: 10px;left: -10px;border-radius: 10px; top: 0px;height: 300px;'>
				</div>
			</div>
			<div @click="$router.push('/member/inquiry')" inquiry :class="['right_btn',iconIndex==2?'check':'']"
				@mouseenter="handleMouseEnter(2)" @mouseleave="handleMouseEnter(0)">
				<img v-if="iconIndex==2" src="@/assets/icon/xunjia1.png" class='ricon' alt="">
				<img v-else src="@/assets/icon/xunjia.png" class='ricon' alt="">
				<div :class="['rightText',iconIndex==2?'checkText':'']">
					立即询价
				</div>
			</div>
			<div @click="$router.push('/member/index')" :class="['right_btn',iconIndex==3?'check':'']"
				@mouseenter="handleMouseEnter(3)" @mouseleave="handleMouseEnter(0)">
				<img v-if="iconIndex==3" src="@/assets/icon/wode1.png" class='ricon' alt="">
				<img v-else src="@/assets/icon/wode.png" class='ricon' alt="">
				<div :class="['rightText',iconIndex==3?'checkText':'']">
					我的
				</div>
			</div>
			<div @click="$router.push('/cart')" :class="['right_btn',iconIndex==4?'check':'']"
				@mouseenter="handleMouseEnter(4)" @mouseleave="handleMouseEnter(0)">
				<img v-if="iconIndex==4" src="@/assets/icon/gowuche1.png" class='ricon' alt="">
				<img v-else src="@/assets/icon/gouwuche.png" class='ricon' alt="">
				<div :class="['rightText',iconIndex==4?'checkText':'']">
					购物车
				</div>
			</div>
			<div @click="$router.push('/member/footprint')" :class="['right_btn',iconIndex==5?'check':'']"
				@mouseenter="handleMouseEnter(5)" @mouseleave="handleMouseEnter(0)">
				<img v-if="iconIndex==5" src="@/assets/icon/liulan1.png" class='ricon' alt="">
				<img v-else src="@/assets/icon/liulan.png" class='ricon' alt="">
				<div :class="['rightText',iconIndex==5?'checkText':'']">
					浏览历史
				</div>
			</div>

			<div @click="toTop" style="margin-top: 30px;border-top: 1px solid #eee;"
				:class="['right_btn',iconIndex==7?'check':'']" @mouseenter="handleMouseEnter(7)"
				@mouseleave="handleMouseEnter(0)">
				<img v-if="iconIndex==7" src="@/assets/icon/dingbu1.png" class='ricon' alt="">
				<img v-else src="@/assets/icon/dingbu.png" class='ricon' alt="">
				<div :class="['rightText',iconIndex==7?'checkText':'']">
					顶部
				</div>
			</div>
		</div>
		<!-- 	<div class="main-sidebar-body">
			<ul>

				<li @click="$router.push('/cart')">
					<div class="cart">
						<i class="el-icon-shopping-cart-2"></i>
						<span>购物车</span>
						<em v-show="cartCount">{{ cartCount }}</em>
					</div>
				</li>
				<li @click="$router.push('/member/order_list')">
					<el-tooltip class="item" effect="dark" content="我的订单" placement="left">
						<el-button><i class="el-icon-tickets"></i></el-button>
					</el-tooltip>
				</li>
				<li @click="$router.push('/member/footprint')">
					<el-tooltip class="item" effect="dark" content="我的足迹" placement="left">
						<el-button><i class="iconfont iconzuji"></i></el-button>
					</el-tooltip>
				</li>
				<li @click="$router.push('/member/collection')">
					<el-tooltip class="item" effect="dark" content="我的关注" placement="left">
						<el-button><i class="iconfont iconlike"></i></el-button>
					</el-tooltip>
				</li>
				<li @click="$router.push('/member/my_coupon')">
					<el-tooltip class="item" effect="dark" content="我的优惠券" placement="left">
						<el-button><i class="iconfont iconyouhuiquan"></i></el-button>
					</el-tooltip>
				</li>
			</ul>
			<a class="back-top" :class="{ showBtn: visible }" title="返回顶部" @click="toTop"><i
					class="el-icon-top"></i></a>
		</div> -->
		<div class="main-sidebar-right">
			<div id="mainSidebarHistoryProduct" class="history-product"></div>
		</div>
		<!--联系客服弹窗-->
		<servicerMessage ref="servicerMessage" class="kefu" :shop="{shop_id:0,logo:siteInfo.logo,shop_name:'平台客服'}">
		</servicerMessage>
	</aside>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		shopServiceOpen,
		advytan
	} from "@/api/website.js"
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		props: {},
		data() {
			return {
				er_code: '',
				one_tel: '',
				two_tel: '',
				visible: false,
				hackReset: false,
				serverType: 'disable',
				serverThird: '',
				iconIndex: 0,
				showTan: false
			}
		},
		components: {
			servicerMessage
		},
		computed: {
			...mapGetters(["cartCount", "siteInfo", 'member']),
			logined: function() {
				return this.member !== undefined && this.member !== "" && this.member !== {}
			}
		},
		created() {
			this.shopServiceOpen()
			this.getKefu()
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll)
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll)
		},
		watch: {},
		methods: {
			handleMouseEnter(e) {
				let that = this;
				if (e == 1) {
					that.showTan = true;
				} else {
					that.showTan = false;
				}
				that.iconIndex = e;
				console.log('进入')

			},
			handleScroll() {
				this.visible = window.pageYOffset > 300
			},
			goKefus(){
				window.open('https://work.weixin.qq.com/kfid/kfc7335d979c1a15b66')
			},
			getKefu() {
				advytan().then((res) => {
					if (res.code == 0) {
						console.log('kefu')
						console.log(res)
						console.log(res.data)
						this.er_code = res.data.er_code;
						this.two_tel = res.data.two_tel;
						this.one_tel = res.data.one_tel;
					}
				})
			},
			shopServiceOpen() {
				shopServiceOpen().then((res) => {
					if (res.code == 0) {
						if (res.data.type == 'third') {
							this.serverType = res.data.type
							this.serverThird = res.data.third
						} else if (res.data.type == 'system') {
							this.serverType = res.data.type
							this.serverThird = ''
						}
					}
				})
			},
			toTop() {
				let timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop
					let ispeed = Math.floor(-osTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
					this.isTop = true
					if (osTop === 0) {
						clearInterval(timer)
					}
				}, 20)
			},
			// 打开客服弹窗
			showServiceFn() {
				if (this.logined) {
					if (this.serverType == 'third') {
						window.open(this.serverThird, "_blank");
					} else if (this.serverType == 'system') {
						this.hackReset = true;
						this.$refs.servicerMessage.show()
					}
				} else {
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
				}

			},
		},
	}
</script>
<style scoped type="text/css">
	.check {
		background-color: #ed0c1e !important;
	}

	.checkText {
		color: #fff !important;
	}

	.right-Warp {
		position: fixed;
		border-radius: 4px;
		/* overflow: hidden; */
		right: 0;
		width: 68px;
		height: 483px;
		top: 50%;
		margin-top: -204px;
	}

	.ricon {
		width: 23px;
		height: 23px;
		margin-top: 8px;
	}

	.rightText {
		margin-top: 4px;
		font-size: 12px;
		color: #333;
	}

	.right_btn {
		background-color: #fff;
		text-align: center;
		width: 68px;
		height: 58px;
		cursor: pointer;
		background: #fff;
		line-height: 18px;
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}
</style>
<style scoped lang="scss">
	.main-sidebar {
		width: 340px;
		height: 100%;
		position: fixed;
		top: 0;
		right: -300px;
		z-index: 400;

		.main-sidebar-body {
			width: 40px;
			height: 100%;
			float: left;
			background-color: #333333;

			ul {
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);

				li {
					position: relative;

					.cart {
						height: auto;
						line-height: 20px;
						padding: 11px 0;
						text-align: center;
						cursor: pointer;

						span {
							display: block;
							padding: 5px 9px;
							text-align: center;
						}

						em {
							min-width: 17px;
							height: 15px;
							line-height: 15px;
							display: inline-block;
							padding: 0 2px;
							color: #ffffff;
							font-size: 10px;
							font-style: normal;
							text-align: center;
							border-radius: 8px;
							background-color: $base-color;
						}

						&:hover em {
							color: $base-color;
							background-color: #fff;
						}
					}
				}
			}

			a,
			.cart,
			.el-button {
				width: 40px;
				height: 40px;
				line-height: 40px;
				display: block;
				margin-bottom: 10px;
				color: #ffffff;
				text-align: center;
				-webkit-transition: background-color 0.3s;
				transition: background-color 0.3s;
				padding: 0;
				border: none;
				background: transparent;

				&:hover {
					background-color: $base-color;
				}
			}

			.back-top {
				display: none;
				margin-bottom: 0;
				position: absolute;
				bottom: 0;
				border-top: 1px solid #888888;
			}

			.showBtn {
				display: inline;
				opacity: 1;
				cursor: pointer;
			}

			i {
				font-size: 16px;
			}
		}
	}

	.kefuTip .tip {
		display: none;
	}

	.kefuTip:hover .tip {
		display: block;
		position: absolute;
		right: 40px;
		top: 50%;
		transform: translate(0, -50%);

		.tip_item {
			border-top-left-radius: 118px;
			border-top-right-radius: 118px;
			margin-right: 13px;
			width: 100px;
			background: #FF4649;
			color: #fff;
			padding-bottom: 1px;

			&::after {
				content: "";
				position: absolute;
				right: 0px;
				top: 50%;
				transform: translate(-8px, -50%) rotate(45deg);
				height: 10px;
				width: 10px;
				background-color: #ff4649;
			}
		}

		.kefu_logo {
			width: 78px;

			margin: 0 auto 10px;
			border-radius: 50%;

			img {
				margin-top: 14px;
				background: linear-gradient(to top right, #e4e4e4, #FFF);
				border-radius: 50%;
				width: 100%;
				height: 78px
			}
		}

		.text {
			padding: 0 !important;
			background: #fff;
			margin: 0 10px 10px;
			color: #FF4649;
			border-radius: 3px;
			text-align: center;
			line-height: 30px;

		}
	}

	.cart {
		span {
			display: block;
			padding: 5px 9px;
			text-align: center;
		}

		em {
			min-width: 17px;
			height: 15px;
			line-height: 15px;
			display: inline-block;
			padding: 0 2px;
			color: #ffffff;
			font-size: 10px;
			font-style: normal;
			text-align: center;
			border-radius: 4px;
			background-color: $base-color;
		}

		&:hover em {
			color: $base-color;
			background-color: #fff;
		}
	}

	a,
	.cart,
	.el-button {
		width: 40px;
		height: 40px;
		line-height: 40px;
		display: block;
		margin-bottom: 10px;
		color: #ffffff;
		text-align: center;
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
		padding: 0;
		border: none;
		background: transparent;

		&:hover {
			background-color: $base-color;
		}
	}

	.back-top {
		display: none;
		margin-bottom: 0;
		position: absolute;
		bottom: 0;
		border-top: 1px solid #888888;
	}

	.showBtn {
		display: inline;
		opacity: 1;
		cursor: pointer;
	}

	i {
		font-size: 16px;
	}
</style>